import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Divider,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  ListItemIcon,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  backIcon,
  downArrow,
  homeIcon,
  reportingIcon,
  userIcon,
  dashboardIcon,
  projectIcon,
  taskIcon,
  quilaImg,
  searchIcon,
  supportIcon,
  settingIcon,
  profileImg,
  logoutImg,
  backIconImg
} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderComment = () => {
    return (
      <CommentsContainer>
        <Box className="commentTextContainer">
          <Typography className="commentTextStyle">{configJSON.commentText}</Typography>
          <Typography className="commentSecondText">{configJSON.commentTextOne}<br></br>{configJSON.commentTextSecond}</Typography>
        </Box>
        <Box className="containerToggle">
          {this.renderToggleFunSecond()}
          <Box style={webStyle.toogleView}>
            <FormControlLabel
              data-test-id="commentSmsId"
              label="SMS"
              style={webStyle.toogleView2}
              control={<IOSSwitch
                data-test-id="commentSmsChildId"
                checked={this.state.comment_sms}
                onChange={this.handleCommentSms}
              />}
            />
          </Box>
        </Box>
      </CommentsContainer>
    )
  };

  renderToggleFunSecond = () =>{
    return(
      <>
        <Box style={webStyle.toogleView}>
          <FormControlLabel
            data-test-id="toggleTestId"
            style={webStyle.toogleView2}
            label="Push"
            control={<IOSSwitch
              data-test-id="toggleChildID"
              checked={this.state.comment_push}
              onChange={this.handleCommentPush}
            />}
          />
        </Box>
        <Box style={webStyle.toogleView}>
          <FormControlLabel
            data-test-id="commentEmailTestId"
            control={<IOSSwitch
              data-test-id="commentEmailChildTestId"
              checked={this.state.comment_email}
              onChange={this.handleCommentEmail}
              />}
              label="Email"
              style={webStyle.toogleView2}
          />
        </Box>
      </>
    )
  };

  renderToggleFun = () =>{
    return(
      <>
        <Box style={webStyle.toogleView}>
          <FormControlLabel
            style={webStyle.toogleView2}
            label="Push"
            data-test-id="reminderPushId"
            control={<IOSSwitch
              data-test-id="reminderPushChildId"
              checked={this.state.reminder_push}
              onChange={this.handleRemindersPush}
            />}
          />
        </Box>
        <Box style={webStyle.toogleView} >
          <FormControlLabel
            data-test-id="reminderEmailId"
            style={webStyle.toogleView2}
            label="Email"
            control={<IOSSwitch
              data-test-id="reminderEmailChildId"
              checked={this.state.reminder_email}
              onChange={this.handleRemindersEmail}
            />}
          />
        </Box>
      </>
    )
  };

  renderMainDashboard = () => {
    return (
      <Box style={webStyle.containerBox}>
        <Divider orientation="vertical" className="dividerLine" />
        <Box style={webStyle.rightContainer}>
          <MainContainer>
            <BackDashboard >
              <img className="backImgStyle" src={backIcon} />
              <Typography className="dashboardText">{configJSON.backDashboardText}</Typography>
            </BackDashboard>
            <Typography className="settingText">{configJSON.settingText}</Typography>
            <TabsBox 
              data-test-id="tabTestID"
              value={this.state.value}
              onChange={this.handleChange}>
              <TabTxt {...this.a11yProps(0)} label="My details" />
              <TabTxt {...this.a11yProps(1)} label="Profile" />
              <TabTxt {...this.a11yProps(2)} label="Password" />
              <TabTxt {...this.a11yProps(3)} label={
                <Box className="numberMainBox">
                  <span>{configJSON.teamText}</span>
                  <Box className="numberBox">
                    <Typography className="numberText">{configJSON.number}</Typography>
                  </Box>
                </Box>
              } />
              <TabTxt {...this.a11yProps(4)} label="Plan" />
              <TabTxt {...this.a11yProps(5)} label="Billing" />
              <TabTxt {...this.a11yProps(6)} label="Email" />
              <TabTxt {...this.a11yProps(7)} label="Notifications" />
              <TabTxt {...this.a11yProps(8)} label="Integrations" />
              <TabTxt {...this.a11yProps(9)} label="API" />
            </TabsBox>
            <Box className="notificationTextbox">
              <Typography className="notificationText">{configJSON.notificationText}</Typography>
              <Typography className="notificationSecondText">{configJSON.notificationSecondText}</Typography>
            </Box>
            <Divider className="dividerLine" />
            {this.renderComment()}
            <Divider className="dividerLine" />
            {this.renderTags()}
            <Divider className="dividerLine"/>
            <CommentsContainer>
              <Box className="commentTextContainer">
                <Typography className="commentTextStyle">{configJSON.remindertext}</Typography>
                <Typography className="commentSecondText">{configJSON.reminderTextOne}<br></br>{configJSON.reminderTextTwo}</Typography>
              </Box>
              <Box className="containerToggle">
                {this.renderToggleFun()}
                <Box style={webStyle.toogleView} >
                  <FormControlLabel
                   data-test-id="reminderSmsId"
                    style={webStyle.toogleView2}
                    label="SMS"
                    control={<IOSSwitch
                      data-test-id="reminderSmsChildId"
                      checked={this.state.reminder_sms}
                      onChange={this.handleRemindersSms}
                    />}
                  />
                </Box>
              </Box>
            </CommentsContainer>
            <Divider className="dividerLine" />
            <CommentsContainer>
              <Box className="commentTextContainer">
                <Typography className="commentTextStyle">{configJSON.activityText}</Typography>
                <Typography className="commentSecondText">{configJSON.activityTextOne}<br></br> {configJSON.activityTextTwo}<br></br>{configJSON.activityTextThree}</Typography>
              </Box>
              <Box className="containerToggle">
                <Box style={webStyle.toogleView}>
                  <FormControlLabel
                    data-test-id="activityPushId"
                    label="Push"
                    style={webStyle.toogleView2}
                    control={<IOSSwitch
                      data-test-id="activityPushChildId"
                      checked={this.state.activity_push}
                      onChange={this.handleActivityPush}
                    />}
                  />
                </Box>
                <Box style={webStyle.toogleView} >
                  <FormControlLabel
                    data-test-id="activityEmailId"
                    label="Email"
                    style={webStyle.toogleView2}
                    control={<IOSSwitch
                      data-test-id="activityEmailChildId"
                      checked={this.state.activity_email}
                      onChange={this.handleActivityEmail}
                    />}
                  />
                </Box>
                <Box style={webStyle.toogleView} >
                  <FormControlLabel
                    data-test-id="activitySmsId"
                    label="SMS"
                    style={webStyle.toogleView2}
                    control={<IOSSwitch 
                      data-test-id="activitySmsChildId"
                      checked={this.state.activity_sms}
                      onChange={this.handleActivitySms}
                    />}
                  />
                </Box>
              </Box>
            </CommentsContainer>
          </MainContainer>
        </Box>
      </Box>
    )
  }

  renderTags = () => {
    return (
      <CommentsContainer>
        <Box className="commentTextContainer">
          <Typography className="commentTextStyle">{configJSON.tagsText}</Typography>
          <Typography className="commentSecondText">{configJSON.tagsTextOne}<br></br>{configJSON.tagsTextTwo}</Typography>
        </Box>
        <Box className="containerToggle">
          <Box style={webStyle.toogleView}>
            <FormControlLabel
             data-test-id="tagsPushId"
              style={webStyle.toogleView2}
              control={<IOSSwitch
                data-test-id="tagsPushChildId"
                checked={this.state.tags_push}
                onChange={this.handleTagsPush}
              />}
              label="Push"
            />
          </Box>
          <Box style={webStyle.toogleView}>
            <FormControlLabel
              data-test-id="tagsEmailId"
              style={webStyle.toogleView2}
              control={<IOSSwitch
                data-test-id="tagsEmailChildId"
                checked={this.state.tags_email}
                onChange={this.handleTagsEmail}
              />}
              label="Email"
            />
          </Box>
          <Box style={webStyle.toogleView}>
            <FormControlLabel
              data-test-id="tagsSmsId"
              style={webStyle.toogleView2}
              control={<IOSSwitch
                data-test-id="tagsSmsChildId"
                checked={this.state.tags_sms}
                onChange={this.handleTagsSms}
              />}
              label="SMS"
            />
          </Box>
        </Box>
      </CommentsContainer>
    )
  };

  renderSideBar = () => {
    return (
      <SidebarBox>
        <QualImage src={quilaImg} alt="quila" />
        <Box className="boxContainer">
          <Box className="sideBarContainer">
            <Box style={webStyle.searchBarBox}>
              <img src={searchIcon} style={webStyle.searchIconStyle} />
              <input placeholder="Search" style={webStyle.inpuStyle} />
            </Box>
            <Box>
              <List className="MuiList-padding">
                <ListItem >
                  <ListItemIcon className="MuiListItemIcon-root">
                    <img src={homeIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Home" className="homeText" />
                  <Box style={webStyle.dropDownStyle}>
                    <img src={downArrow} />
                  </Box>
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={dashboardIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" className="homeText"/>
                  <Box style={webStyle.dropDownStyle}>
                    <img src={downArrow} />
                  </Box>
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={projectIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Projects" className="homeText" />
                  <Box style={webStyle.dropDownStyle}>
                    <img src={downArrow} />
                  </Box>
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={taskIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Tasks" className="homeText" />
                  <Box style={webStyle.dropDownStyle}>
                    <img src={downArrow} />
                  </Box>
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={reportingIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Reporting"  className="homeText"/>
                  <Box style={webStyle.dropDownStyle}>
                    <img src={downArrow} />
                  </Box>
                </ListItem>
                <ListItem >
                  <ListItemIcon>
                    <img src={userIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Users" className="homeText" />
                  <Box style={webStyle.dropDownStyle}>
                    <img src={downArrow} />
                  </Box>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box>
            <List>
              <ListItem >
                <ListItemIcon>
                  <img src={supportIcon} />
                </ListItemIcon>
                <ListItemText primary="Support" className="homeText" />
              </ListItem>
            </List >
            <List >
              <ListItem >
                <ListItemIcon>
                  <img src={settingIcon} />
                </ListItemIcon>
                <ListItemText primary="Settings" className="homeText"/>
              </ListItem>
            </List>
            <Divider style={webStyle.dividerStyle}/>
            <Box style={webStyle.boxContainer2}>
              <Box style={webStyle.userImgStyle}>
                <img src={profileImg} />
              </Box>
              <Box style={webStyle.userProfileBox}>
                <Typography style={webStyle.userNameText}>{configJSON.emailIdtext}</Typography>
                <Typography style={webStyle.userEmailText}>{configJSON.emailIdtextSecond}</Typography>
              </Box>
              <Box style={webStyle.btnBox}>
                <img src={logoutImg} />
              </Box>
            </Box>
          </Box>
        </Box>
      </SidebarBox>
    )
  };

  renderSmallScreen = () => {
    return (
      <SettingSmallScreen>
        <Box className="backIconImg">
          <img src={backIconImg} />
        </Box>
        <img src={quilaImg} className="logoImg" />
        <Box className="mainBox">
          <Typography className="helpText">{configJSON.helpText}</Typography>
          <Typography className="notificationText" data-test-id="notificationTestID">{configJSON.notificationText}</Typography>
          <Divider />
          <Box className="notificationContainer">
            <Box className="notifyBox">
              <Typography className="notifyText" data-test-id="notifyTextTestId">{configJSON.pushNotificationText}</Typography>
              <FormControlLabel
                data-test-id="pushNotifyTestId"
                className="toogleStyle"
                control={<IOSSwitch
                  data-test-id="notifyTestId"
                  checked={this.state.push_notification}
                  onChange={this.handleChangePushNotification}
                />}
                label=""
              />
            </Box>
            <Box className="notifyBox">
              <Typography className="notifyText">{configJSON.pauseBookingText}</Typography>
              <FormControlLabel
               data-test-id="pauseLikeTestId"
                className="toogleStyle"
                control={<IOSSwitch
                  data-test-id="pauseLikeChildTestId"
                  checked={this.state.pause_like}
                  onChange={this.handleChangePauseLike}
                />}
                label=""
              />
            </Box>
            <Box className="notifyBox">
              <Typography className="notifyText">{configJSON.pauseText}</Typography>
              <FormControlLabel
              data-test-id="pauseBookingTestId"
                className="toogleStyle"
                control={<IOSSwitch
                  data-test-id="pauseBookingChildTestId"
                  checked={this.state.pause_booking}
                  onChange={this.handlePauseBooking}
                />}
                label=""
              />
            </Box>
            <Box className="notifyBox">
              <Typography className="notifyText">{configJSON.alertText}</Typography>
              <FormControlLabel
               data-test-id="commentTestId"
                className="toogleStyle"
                control={<IOSSwitch
                  data-test-id="commentChildTestId"
                  checked={this.state.comment_review}
                  onChange={this.handleCommentReview}
                />}
                label=""
              />
            </Box>
          </Box>

          <Typography className="notificationText">{configJSON.privacyText}</Typography>
          <Divider className="dividerLine" />
          <Box className="privacyContainer">
            <Box className="notifyBox">
              <Typography className="notifyText">{configJSON.hideAllText}</Typography>
              <FormControlLabel
               data-test-id="hideAccountTestId"
                className="toogleStyle"
                control={<IOSSwitch
                  data-test-id="hideAccountChildTestId"
                  checked={this.state.hide_acount}
                  onChange={this.handlehideAcount}
                />}
                label=""
              />
            </Box>
            <Box className="notifyBox">
              <Typography className="notifyText">{configJSON.hideText}</Typography>
              <FormControlLabel
               data-test-id="hideTalentTestId"
                className="toogleStyle"
                control={<IOSSwitch
                  data-test-id="hideTalentChildTestId"
                  checked={this.state.hide_telent_account}
                  onChange={this.handlehideTelentAcount}
                />}
                label=""
              />
            </Box>
            <Box className="notifyBox">
              <Typography className="notifyText" >{configJSON.showText}</Typography>
              <FormControlLabel
                className="toogleStyle"
                data-test-id="showAddressTestId"
                control={<IOSSwitch
                  data-test-id="showAddressChildTestId"
                  checked={this.state.show_address_only_for_approval}
                  onChange={this.handleShowAddress}
                />}
                label=""
              />
            </Box>
          </Box>
          <Typography className="helpText">{configJSON.blockuserText}</Typography>
          <Typography className="suggestionText">{configJSON.suggestionText}</Typography>
        </Box>
        <SaveButton
          type="submit"
          fullWidth
          variant="contained">
          {configJSON.savebtnText}
        </SaveButton>
      </SettingSmallScreen>
    )
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <GridContainer container>
          <Grid item sm={8} lg={3} md={3} xl={3}>
            {this.renderSideBar()}
          </Grid>
          <Grid item sm={8} lg={9} md={9} xl={9}>
            {this.renderMainDashboard()}
          </Grid>
        </GridContainer>
        {this.renderSmallScreen()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  toogleView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    marginBottom: "10px",
    color: "#495668"
  },

  toogleView2: {
    display: "flex",
    gap: "15px",
    width: "100px",
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px"
  },

  searchBarBox: {
    height: "50px",
    border: "1px solid grey",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px",
    margin: "20px"
  },

  inpuStyle: {
    border: "none",
    marginLeft: "5px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    padding: "0 10px",
    width:"100%",
    overflow: "hidden",
    color: "var(--Gray-500, #667085)",
    textOverflow: "ellipsis",
    outline:"none"
  },

  searchIconStyle: {
    width: "20px",
    height: "20px"
  },

  boxContainer2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width:"90%",
    margin:"10px"
  },

  userImgStyle: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "200px"
  },

  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
  },

  userNameText: {
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px"
  },

  userEmailText: {
    color: "#475467",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px"
  },

  containerBox:{
    display: "flex",
    height: "100%"
  },

  dropDownStyle: {
    marginRight: "0px"
  },

  rightContainer:{ 
    display: "flex" ,
    padding:"32px"
  },

  dividerStyle:{
    margin:"15px"
  },

  userProfileBox:{
    width:"70%"
  }
};

const MainContainer = styled(Box)({
  height: "100%",
  width: "100%",
  "& .settingText": {
    color: "#101828",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "38px",
    padding: "25px 0px 0px 25px"
  },

  "& .notificationText": {
    color: "#101828",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px"
  },

  "& .notificationSecondText": {
    overflow: "hidden",
    color: "#475467",
    textOverflow: "ellipsis",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    marginTop: "5px"
  },

  "& .tabBoxStyle": {
    display: "flex",
    gap: "6px",
    padding: "0px 12px"
  },

  "& .tabsTextStyle": {
    color: "#667085",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    padding: "8px 12px"
  },

  "& .notificationTextbox": {
    padding: "32px 0px 0px 24px",
    gap: "10px"
  },
  "& .dividerLine":{ 
    marginTop: "20px",
    marginLeft:"20px"
  } 
});

const GridContainer = styled(Grid)({
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const BackDashboard = styled(Box)({
  display: "flex",
  flexDirection: "row",
  padding: "25px 25px 0px",
  gap: "6px",
  "& .backImgStyle": {
    width: "20px",
    height: "20px"
  },

  "& .dashboardText": {
    color: "#6941C6",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px"
  }
});

const CommentsContainer = styled(Box)({
  display: "flex",
  gap: "16px 64px",
  padding: "25px",
  "& .commentTextContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "35%"
  },

  "& .commentTextStyle": {
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px"
  },

  "& .commentSecondText": {
    color: "#475467",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    marginTop:"5px"
  },

  "& .containerToggle": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },

  "& .toggleImg": {
    height: "40px"
  },

  "& .pushTextStyle": {
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px"
  }
});

const TabsBox = styled(Tabs)({
  backgroundColor: "#fff",
  display: "flex",
  gap: "5px",
  padding: "25px 10px 0px 25px",
  "&.MuiPaper-elevation4": {
    boxShadow: "none"
  },

  "& .MuiTabs-indicator":{
    backgroundColor: "transparent",
  },

  "& .Mui-selected ": {
    background: "#ededf1",
    borderRadius:"6px"
  },
  "@media (min-width: 600px)": {
    "& .MuiTab-root": {
      minWidth: "90px",
      gap: "4px"
    }
  },

  "& .numberBox": {
    height:"25px",
    width:"25px",
    border:"1px solid grey",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius: "20px",
    marginLeft: "10px"
  },

  "& .numberMainBox": {
    display: "flex",
    alignItems: "center"
  },

  "& .numberText":{
    color:"#344054",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  }
});

const TabTxt = styled(Tab)({
  color: "#667085",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
  textTransform: "initial",
  "& .MuiTab-root": {
    width:"100px"
  }
});

const IOSSwitch = styled((props: React.ComponentProps<typeof Switch>) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#7F56D9",
        opacity: 1,
        border: "none"
      },
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#f5f5f5"
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7
    },

    "& .MuiSwitch-input": {
      left: "-100%",
      width: "300%",
      padding: "60px",
      height: "50px",
      margin:"15px"
    }
  },

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: "background-color 500ms"
  }
});

const QualImage = styled("img")({
  xheight: "10x0%",
  objectFit: "cover",
  padding: "10px",
  width: "50%",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "33%"
  }
});

const SidebarBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& .boxContainer": {
    display: "flex",
    flexDirection: "column",
    height:"100%",
    justifyContent: "space-between",
    gap: "50%"
  },

  "& .sideBarContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },

  "& .MuiList-padding": {
    paddingBottom: "8px",
    margin: "0px 10px -12px"
  },

  "& .MuiListItemIcon-root": {
    color: "rgba(0, 0, 0, 0.54)",
    display: "inline-flex",
    minWidth: "42px",
    flexShrink: 0
  },

  "@media (max-width: 550px)": {
    display: 'none',
    background:"red",
    height:"200px",
    width:"200px"
  },

  "& .homeText": {
    color: "var(--Gray-700, #344054)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineWeight: "24px"
  }
});

const SettingSmallScreen = styled(Box)({
  display: "none",
  "@media (max-width: 600px)": {
    display:"block",
    padding:"0 10px"
  },

  "& .backIconImg":{
    padding: "20px"
  },

  "& .logoImg":{
    padding: "10px 20px 10px 110px" 
  },

  "& .mainBox": {
    margin: "15px"
  },

  "& .helpText": {
    color: "#6551EA",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.32px"
  },

  "& .notificationText": {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
    letterSpacing: "-0.32px",
    marginTop: "10px"
  },

  "& .notifyBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  "& .toggleImgs": {
    width: "16px",
    height: "16px"
  },

  "& .notifyText": {
    color: " #000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.32px"
  },

  "& .notificationContainer": {
    paddingTop: "10px",
    height: "140px",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column"
  },

  "& .privacyContainer": {
    paddingTop: "10px",
    height: "140px",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column"
  },

  "& .suggestionText": {
    color: "#6551EA",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.32px",
    paddingTop: "10px"
  },

  "& .MuiDivider-root":{
    margin: "10px 0px"
  },
  
  "& .toogleStyle": {
    height: "17px",
    width:"17px"
  },
});

const SaveButton = styled(Button)({
  marginTop: '3rem',
  textTransform: 'none',
  backgroundColor: '#0e1518',
  height: '45px',
  color: "#fff",
  fontSize: "15px",
  fontWeight: 500,
  marginBottom: "15px",
  "&:hover": {
    backgroundColor: '#0e1518'
  }
});
// Customizable Area End
