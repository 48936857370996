export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const quilaImage = require("../assets/quilaImage.png");
export const blackArrow = require("../assets/BlackArrow.png");
export const points = require("../assets/points.png");
export const numbers = require("../assets/numbers.png");
export const bold = require("../assets/bold.png");
export const upload = require("../assets/upload.png");
export const mail = require("../assets/mail.png");
export const contrastborder = require("../assets/contrastborder.png");
export const checkbox = require("../assets/checkbox.png");
export const pdf = require("../assets/pdf.png");
export const jpg = require("../assets/jpg.png");
export const filemp = require("../assets/filemp.png");
export const visa = require("../assets/visa.png");
export const fig = require("../assets/fig.png");
export const fontstyle = require("../assets/fontstyle.png");
export const featured = require("../assets/featured.png");
export const avatar = require("../assets/avatar.png");
export const helpcircle = require("../assets/helpcircle.png");
export const content = require("../assets/content.png");
export const eye = require("../assets/eye.png");
export const header = require("../assets/header.png");
export const fileupload = require("../assets/fileupload.png");














