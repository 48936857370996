import React from "react";
// Customizable Area Start
import {
    Grid,
    Button,
    Typography,
    styled,
    TextField
} from "@material-ui/core";
import { lock, checkCircle, quilaImage, blackArrow, checkIcon, redCheck } from "./assets";
// Customizable Area End
import ForgotPasswordController, {
    Props,
    configJSON,
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderResetPassword = () => {
        return (
            <Grid>
                <BackIcon src={blackArrow} />
                <QualImage src={quilaImage} alt="quila" />
                <MainContainer>
                    <ForgetContainer>
                        <KeyContainer>
                            <KeyImage src={lock} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>
                            {configJSON.setPassword}
                        </ForgetText>
                        <TextContainer>
                            <SendText>
                                {configJSON.diffPassword}
                            </SendText>
                            <SendText>
                                {configJSON.previousPassword}
                            </SendText>
                        </TextContainer>
                        <TextForm noValidate onSubmit={this.ForgetPasswordReset} data-test-id="submitTestID">
                            {this.renderNewPassword()}
                            {this.renderTextinput()}
                            <ValidationContainer>
                                <GreyCircle src={this.state.numberOne || this.state.numberTwo ? redCheck : checkIcon} />
                                <CircleText>{configJSON.eightCharacter}</CircleText>
                            </ValidationContainer>
                            <ValidationContainer>
                                <GreyCircle src={this.state.specCharOne || this.state.specCharTwo ? redCheck : checkIcon} />
                                <CircleText>{configJSON.specialCharacter}</CircleText>
                            </ValidationContainer>
                            <SubmitButton
                                type="submit"
                                fullWidth
                                variant="contained">
                                {configJSON.managerPasswordMsg}
                            </SubmitButton>
                        </TextForm>
                    </ForgetContainer>
                </MainContainer>
            </Grid>
        )
    }

    renderNewPassword = () => {
        return (
            <EmailTextInput data-test-id="passwordInputID"
                type="password"
                error={this.state.numberOne || this.state.specCharOne}
                required={this.state.numberOne || this.state.specCharOne ? true : false}
                label={this.state.inputFirst ? "" : "Create new password"}
                variant="outlined"
                value={this.state.inputFirst}
                fullWidth
                onChange={(e) => this.setPassword(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.numberOne || this.state.specCharOne ? "red" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.numberOne || this.state.specCharOne ? "red" : "#757676",
                        border: this.state.numberOne || this.state.specCharOne ? "1px solid red" : "",
                        backgroundColor: this.state.numberOne || this.state.specCharOne ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        borderRadius: "7px"
                    }
                }}
            />
        )
    }

    renderTextinput = () => {
        return (
            <EmailTextInput data-test-id="passwordID"
                type="password"
                error={this.state.numberTwo || this.state.specCharTwo}
                required={this.state.numberTwo || this.state.specCharTwo ? true : false}
                label={this.state.inputSecond ? "" : "Confirm new password"}
                variant="outlined"
                value={this.state.inputSecond}
                fullWidth
                onChange={(e) => this.setConfirmPassword(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.numberTwo || this.state.specCharTwo ? "red" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.numberTwo || this.state.specCharTwo ? "red" : "#757676",
                        border: this.state.numberTwo || this.state.specCharTwo ? "1px solid red" : "",
                        backgroundColor: this.state.numberTwo || this.state.specCharTwo ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        borderRadius: "7px"
                    }
                }}
            />
        )
    }

    renderOpenModal = () => {
        return (
            <Grid>
             <Button onClick={this.handleCloseModal}>
                <BackIcon src={blackArrow} />
                </Button>
                <QualImage src={quilaImage} alt="quila" />
                <MainContainer>
                    <EmailContainer>
                        <KeyContainer>
                            <KeyImage src={checkCircle} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>Password reset</ForgetText>
                        <GreyText>
                        <SendText>
                            {configJSON.successReset}
                        </SendText>
                        <SendText>{configJSON.clicktoLogin}</SendText>
                        </GreyText>
                        <EmailButton
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            {configJSON.continue}
                        </EmailButton>
                    </EmailContainer>
                </MainContainer>
            </Grid>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {this.state.isModalOpen ? this.renderOpenModal() : this.renderResetPassword()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
})

const ForgetContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '22px',
    "@media (max-width: 599px)": {
        marginTop: '-35px'
    },
})

const TextForm = styled("form")({
    width: '370',
})

const SubmitButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#101828',
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    top: '16px',
    "&:hover": {
        backgroundColor: '#101828'
    }
})

const ForgetText = styled(Typography)({
    fontSize: '24px',
    fontWeight: 600,
    color: '#101828',
    marginTop: '20px'
})

const SendText = styled(Typography)({
    textAlign: 'center',
    color: '#757676',
    fontSize: '14px',
    fontWeight: 400,
})

const KeyImage = styled('img')({
})

const ValidationContainer = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px'
})

const GreyCircle = styled("img")({
    width: '20px',
    height: '20px',
    backgroundColor: '#D0D5DD',
    borderRadius: '10px',
    marginRight: '10px',
})

const KeyContainer = styled(Grid)({
    width: "56px",
    height: '56px',
    border: '1px solid #EAECF0',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '12px',
    alignItems: 'center'
})

const QualImage = styled("img")({
    margin: '4px',
    objectFit: 'cover',
    display: 'flex',
    "@media (max-width: 599px)": {
        display: 'none',
    },

})

const CircleText = styled(Typography)({
    fontSize: '14px',
    fontWeight: 400,
    color: '#757676'
})

const TextContainer = styled(Grid)({
    margin: '12px'
})

const BackIcon = styled("img")({
    display: "none",
    "@media (max-width: 599px)": {
        padding: "55px 10% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
    },
    "@media (max-width: 435px)": {
        padding: "10px 5% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginTop: "20px"
    }
})

const EmailTextInput = styled(TextField)({
    borderRadius: "4px",
    border: "#757676",
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    '&::placeholder': {
        color: 'blue'
    }

})

const EmailButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#101828',
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    top: '16px',
    marginTop: "8px",
    width: '358px',
    "&:hover": {
        backgroundColor: '#101828'
    }
})

const GreyText = styled(Grid)({
    marginTop:'12px'
})


const EmailContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '358px',
    "@media (max-width: 599px)": {
        marginTop: '-400px'
    },
})

// Customizable Area End
